import React from "react";
import { router } from "dva";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Affix, Menu, Drawer, Button } from "antd";
import { template, getLocalStorage, setLocalStorage } from "@reco-m/core";
import { ViewComponent, mtaH5Click } from "@reco-w/core-ui";
// import { parkhelperText } from "@reco-w/common-common";
import { Namespaces, headerModel } from "@reco-w/layout-models";
const { SubMenu } = Menu;

export namespace PageHeader {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, headerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        get parkcode() {
            return this.props.match!.params.parkcode;
        }

        componentDidMount() {
            this.dispatch({ type: "input", data: { current: getLocalStorage("selectedMenu") } });
            setTimeout(() => {
                this.dispatch({ type: "input", data: { current: getLocalStorage("selectedMenu") } });
            }, 200);
        }

        /**
         * 渲染目录栏
         * @returns
         */

        handleClick = (e) => {
            setLocalStorage("selectedMenu", e.key);
            this.dispatch({ type: "input", data: { current: e.key, drawerState: false } });
        };

        getSelectedKeys() {
            const selectedMenuText = getLocalStorage("selectedMenu");
            let selectedMenu = selectedMenuText;

            return [selectedMenu || "home"];
        }
        showMenu(e) {
            this.dispatch({ type: "input", data: { drawerState: e } });
        }

        renderSubMenuTitle(title) {
            return <span className="size-18">{title}</span>;
        }
        renderMenuItem(key, link, event, title, flag?): React.ReactNode {
            return (
                <Menu.Item key={key}>
                    <router.Link
                        to={link}
                        className={flag ? "size-18" : ""}
                        onClick={() => {
                            mtaH5Click(event);
                        }}
                    >
                        {title}
                    </router.Link>
                </Menu.Item>
            );
        }

        renderMenu(): React.ReactNode {
            const { state } = this.props,
                drawerState = state!.drawerState;
            return (
                <>
                    <Menu onClick={this.handleClick} selectedKeys={this.getSelectedKeys()} mode="horizontal" className="index-header">
                        <Menu.Item key={"home"}>
                            <router.Link to={"/home"}>首页</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"contracts"}>
                            <router.Link to={"/contracts"}>委托选址</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"estate"}>
                            <router.Link to={"/investmentsolution"}>产业招商</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"yunqi"}>
                            <router.Link to={"/about"}>关于我们</router.Link>
                        </Menu.Item>
                        {/*<Menu.Item key={"aboutus"}>*/}
                        {/*    <router.Link to={"/aboutus"}>了解云企</router.Link>*/}
                        {/*</Menu.Item>*/}
                        {/*<SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("产业招商")}>*/}
                        {/*    {this.renderMenuItem("/entrust", `/entrust`, statisticsEvent.navigationBarDemand, "委托招商")}*/}
                        {/*    {this.renderMenuItem("/operating", `/operating`, statisticsEvent.navigationBarMeetingroom, "飞地运营")}*/}
                        {/*    {this.renderMenuItem("/promote", `/promote`, statisticsEvent.navigationBarApplication, "招商推介")}*/}
                        {/*    {this.renderMenuItem("/economics", `/economics`, statisticsEvent.navigationBarComplaint, "总部经济")}*/}
                        {/*</SubMenu>*/}
                        {/*<SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("企业服务")}>*/}
                        {/*    {this.renderMenuItem("/selection", `/selection`, statisticsEvent.navigationBarDemand, "企业选址")}*/}
                        {/*    {this.renderMenuItem("/zoology", `/zoology`, statisticsEvent.navigationBarMeetingroom, "大厂生态")}*/}
                        {/*    {this.renderMenuItem("/aliyun", `/aliyun`, statisticsEvent.navigationBarApplication, "企业上云")}*/}
                        {/*    {this.renderMenuItem("/financing", `/financing`, statisticsEvent.navigationBarComplaint, "投融资")}*/}
                        {/*</SubMenu>*/}
                        {/*<Menu.Item key={"classiccase"}>*/}
                        {/*    <router.Link to={"/classiccase"}>客户案例</router.Link>*/}
                        {/*</Menu.Item>*/}
                        {/*<SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("客户案例")}>
                            {this.renderMenuItem("/classiccase", `/classiccase`, statisticsEvent.navigationBarDemand, "经典案例")}
                            {this.renderMenuItem("/", `/`, statisticsEvent.navigationBarMeetingroom, "标杆客户")}
                        </SubMenu>*/}
                        {/*<Menu.Item key={"information"}>*/}
                        {/*    <router.Link to={"/information"}>新闻中心</router.Link>*/}
                        {/*</Menu.Item>*/}
                        <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("瑞谷矩阵")}>
                            <Menu.Item>
                                <a target="_blank" href="https://reco.cn/home">
                                    瑞谷集团
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a target="_blank" href="http://www.bitech.cn/">
                                    瑞谷软件
                                </a>
                            </Menu.Item>
                            {/*{this.renderMenuItem("/scientific", `/scientific`, statisticsEvent.navigationBarMeetingroom, "瑞谷科创")}*/}
                            {/*{this.renderMenuItem("/aboutus", `/aboutus`, statisticsEvent.navigationBarMeetingroom, "瑞谷云企")}*/}
                            {/*{this.renderMenuItem("/institute", `/institute`, statisticsEvent.navigationBarMeetingroom, "瑞谷研究院")}*/}
                        </SubMenu>

                        {/*<Menu.Item key={"office"}>
                            <router.Link to={"/office"}>智能办公</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"inn"}>
                            <router.Link to={"/inn"}>园客栈</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"cooperate"}>
                            <router.Link to={"/cooperate"}>招商&采购</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"about"}>
                            <router.Link to={"/about"}>关于{parkhelperText}</router.Link>
                        </Menu.Item>*/}
                    </Menu>

                    <div className="phone menu">
                        <div className="down-icon">
                            <UnorderedListOutlined onClick={() => this.showMenu(true)} />
                        </div>
                    </div>
                    <Drawer placement="right" closable={false} width={"80%"} maskClosable onClose={() => this.showMenu(false)} visible={drawerState}>
                        <Menu className="mobile-menu" onClick={this.handleClick} selectedKeys={this.getSelectedKeys()} mode="inline">
                            <Menu.Item key={"home"}>
                                <router.Link to={"/home"} onClick={() => this.showMenu(false)}>
                                    首页
                                </router.Link>
                            </Menu.Item>
                            <Menu.Item key={"contracts"}>
                                <router.Link to={"/contracts"}>委托选址</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"estate"}>
                                <router.Link to={"/investmentsolution"}>产业招商</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"yunqi"}>
                                <router.Link to={"/about"}>关于我们</router.Link>
                            </Menu.Item>
                            {/*<Menu.Item key={"aboutus"}>*/}
                            {/*    <router.Link to={"/aboutus"}>了解云企</router.Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("产业招商")}>*/}
                            {/*    {this.renderMenuItem("/entrust", `/entrust`, statisticsEvent.navigationBarDemand, "委托招商")}*/}
                            {/*    {this.renderMenuItem("/operating", `/operating`, statisticsEvent.navigationBarMeetingroom, "飞地运营")}*/}
                            {/*    {this.renderMenuItem("/promote", `/promote`, statisticsEvent.navigationBarApplication, "招商推介")}*/}
                            {/*    {this.renderMenuItem("/economics", `/economics`, statisticsEvent.navigationBarComplaint, "总部经济")}*/}
                            {/*</SubMenu>*/}
                            {/*<SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("企业服务")}>*/}
                            {/*    {this.renderMenuItem("/selection", `/selection`, statisticsEvent.navigationBarDemand, "企业选址")}*/}
                            {/*    {this.renderMenuItem("/zoology", `/zoology`, statisticsEvent.navigationBarMeetingroom, "大厂生态")}*/}
                            {/*    {this.renderMenuItem("/aliyun", `/aliyun`, statisticsEvent.navigationBarApplication, "企业上云")}*/}
                            {/*    {this.renderMenuItem("/financing", `/financing`, statisticsEvent.navigationBarComplaint, "投融资")}*/}
                            {/*</SubMenu>*/}
                            {/*<Menu.Item key={"classiccase"}>*/}
                            {/*    <router.Link to={"/classiccase"}>客户案例</router.Link>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item key={"information"}>*/}
                            {/*    <router.Link to={"/information"}>新闻中心</router.Link>*/}
                            {/*</Menu.Item>*/}
                            <SubMenu popupClassName="ipark-menu" title={this.renderSubMenuTitle("瑞谷矩阵")}>
                                <Menu.Item>
                                    <a target="_blank" href="https://reco.cn/home">
                                        瑞谷集团
                                    </a>
                                </Menu.Item>
                                <Menu.Item>
                                    <a target="_blank" href="http://www.bitech.cn/">
                                        瑞谷软件
                                    </a>
                                </Menu.Item>
                                {/*{this.renderMenuItem("/scientific", `/scientific`, statisticsEvent.navigationBarMeetingroom, "瑞谷科创")}*/}
                                {/*{this.renderMenuItem("/aboutus", `/aboutus`, statisticsEvent.navigationBarMeetingroom, "瑞谷云企")}*/}
                                {/*{this.renderMenuItem("/institute", `/institute`, statisticsEvent.navigationBarMeetingroom, "瑞谷研究院")}*/}
                            </SubMenu>
                        </Menu>
                        <div className="ph24 mt15">
                            <div className="text-center primary-color">400-026-9898</div>
                            <div className="mt15"><Button type="primary" block onClick={() => this.goTo("/apply")}>立即咨询</Button></div>
                        </div>
                    </Drawer>
                </>
            );
        }

        render(): React.ReactNode {
            return (
                <div className="head-menu">
                    <Affix offsetTop={0}>
                        <div className="header new-header">
                            <div className="container">
                                <div className="flex width-full">
                                    <div className="head-logo">
                                        <img src={"assets/images/second/logo2.png"} alt="" />
                                    </div>
                                    <div className="flex-item">{this.renderMenu()}</div>
                                    <div className="head-tel">
                                        <span>400-026-9898</span>
                                        <Button type="primary" onClick={() => this.goTo("/apply")}>立即咨询</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Affix>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
